<template>
  <a-layout-content class="content">
    <a-page-header style="padding: 0" title="Projects" />
    <a-table :dataSource="list" :columns="columns" :loading="listloading" :pagination="{
      hideOnSinglePage: true,
      total: total,
      current: currentPage,
      defaultPageSize: limit,
    }" rowKey="id" @change="tableChange" class="ant-table-striped" :rowClassName="
  (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
">
      <template #name="{ text }"> {{ text }} </template>
      <template #username="{ text }"> {{ text }} </template>
      <template #email="{ text }">
        {{ text }}
      </template>
      <template #status="{ text }">
        {{ text }}
      </template>

      <template #lastLoginTime="{ text }">
        {{ text ? $filters.formatTime(text) : "" }}
      </template>
      <!-- <template #tool="{ text }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showStatusModal = true;
                    upateStatusI = text;
                    newStatus = list[text].status;
                  "
                  >Status</a
                >
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="openRolesModal(text)">Roles</a>
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showsalaryModal = true;
                    editsalary = text;
                    getsalary(text);
                  "
                  >Solary</a
                >
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click.stop.prevent="showDeleteConfirm(text)"
                  >Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template> -->
    </a-table>
  </a-layout-content>
</template>

<script>
// @ is an alias to /src
import Chart from "@/components/Chart.vue";
import gql from "graphql-tag";
import { useQuery, useResult } from "@vue/apollo-composable";
import { reactive, toRefs } from "@vue/reactivity";

import { SettingOutlined } from "@ant-design/icons-vue";
import { inject } from "@vue/runtime-core";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: 80,
  },
  {
    dataIndex: "namespace",
    title: "Namespace",
    slots: { customRender: "name" },
  },
  {
    dataIndex: "name",
    title: "Name",
    slots: { customRender: "name" },
  },

  {
    title: "Web Url",
    dataIndex: "webUrl",
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    slots: { customRender: "lastLoginTime" },
  },
  // {
  //   title: "...",
  //   key: "tool",
  //   dataIndex: "key",
  //   slots: { customRender: "tool" },
  // },
];
export default {
  components: {
    Chart,
    SettingOutlined,
  },
  setup() {
    const { $msg } = inject("$");
    const v = reactive({
      limit: 20,
      total: 0,
      currentPage: 1,
      page: 1,
    });

    const QL = gql`
      query {
        projects {
          languages
          id
          name
          webUrl
          createdAt
          namespace
        }
      }
    `;

    const { result, onResult, loading, onError, refetch } = useQuery(QL);

    const list = useResult(result, null, (data) => data.projects);
    onResult((queryResult) => {
      if (!queryResult.data) return;
      console.log(queryResult.data);
    });

    onError((error) => {
      $msg.error("Network error");
    });

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
    }

    return {
      ...toRefs(v),
      list,

      listloading: loading,
      columns,
      tableChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
</style>
